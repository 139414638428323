@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir.ttc") format("truetype-collection");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirLTProMedium";
  src: url("./assets/fonts/AvenirLTProMedium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("./assets/fonts/Avenir Next.ttc") format("truetype-collection");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-VariableFont_wght.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "FondyScript";
  src: url("./assets/fonts/FondyScript_PERSONAL_USE_ONLY.ttf")
    format("truetype");
  font-weight: 400; /* Normal weight */
  font-style: normal; /* Normal style */
}

body {
  margin: 0;
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
  --family-fontSize: 60px;
}

body:has(.qr-page),
body:has(.banner-page) {
  background-color: #002051;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  min-height: 100dvh;
  width: 100%;
  height: 100%;
  position: relative;
}

.main-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%) scale(1);
  overflow: hidden;
}

.phone-form .bg-image {
  background-image: url(/public/image/phoneBg.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.qr-bg-image:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 500px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 32, 81, 1) 100%
  );
}

.bg-image:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 32, 81, 1) 100%
  );
}

.top-shadow.bg-image:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 32, 81, 1) 100%
  );
}

.name-spark::after {
  content: "";
  position: absolute;
  background-image: url(/public/gif/starsHorizontal02.gif);
  width: 800px;
  height: 364px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  transform: translateX(-50%);
}

.init-name-spark::after {
  content: "";
  position: absolute;
  background-image: url(/public/gif/starsHorizontal01.gif);
  width: 1562px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  transform: translateX(-50%);
  height: 220px;
}

.init-name-spark-2::after {
  content: "";
  position: absolute;
  background-image: url(/public/gif/starsHorizontal01.gif);
  width: 1562px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  transform: translateX(-50%);
  height: 220px;
}

.thank-you-wrapper {
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 32, 81, 1) 15%,
    rgba(0, 32, 81, 1) 100%
  );
}

.name-spark {
  -webkit-animation: animateSpark ease 200ms 2000ms forwards;
  animation: animateSpark ease 200ms 2000ms forwards;
}

.init-name-spark,
.init-name-spark-2 {
  -webkit-animation: animateSpark ease 3000ms forwards;
  animation: animateSpark ease 3000ms forwards;
}

@-webkit-keyframes animateSpark {
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}

@keyframes animateSpark {
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}

@media screen and (orientation: landscape) {
  .main-wrapper {
    width: 1920px;
    height: 1080px;
  }

  .main-sub-wrapper {
    flex-direction: row-reverse;
  }

  .before-qr-wrapper {
    height: 100%;
    width: 800px;
    margin: 0 60px;
  }

  .before-video-wrapper {
    height: 100%;
    width: 100%;
  }

  .before-logo {
    top: 50px;
    left: 50px;
    bottom: auto;
    right: auto;
    width: 278px;
    height: 128.65px;
  }

  .before-video {
    /* transform: translate(90px, 55px); */
    transform: unset;
  }

  .before-meyo-jar {
    display: none;
  }

  .after-main-wrapper {
    flex-direction: row;
    height: 1080px;
  }

  .after-video-wrapper {
    height: 100%;
    width: 100%;
  }
  .after-qr-wrapper {
    height: 100%;
    width: 800px;
  }

  .after-video-wrapper::after {
    position: absolute;
    content: "";
    width: 300px;
    height: 100%;
    bottom: auto;
    left: auto;
    right: 0;
    top: 0;
    transform: translateX(10px);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 32, 81, 1) 100%
    );
  }

  .after-qr-nameFrame-wrapper {
    transform: translateX(-37px);
    margin-top: 100px;
  }

  .family-name-box {
    top: 330px;
    font-size: var(--family-fontSize);
    max-width: 350px;
  }

  .after-qr-content-wrapper {
    transform: translate(189px, 100px) scale(60%);
  }

  .name-spark {
    top: 210px;
  }

  .ads-support-form,
  .mobile-form {
    flex-direction: row-reverse;
  }

  .bg-image:after {
    position: absolute;
    content: "";
    width: 100px;
    height: 100%;
    bottom: auto;
    left: auto;
    right: 0;
    top: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 32, 81, 1) 100%
    );
  }

  .top-shadow.bg-image:before {
    display: none;
  }

  .ads-support-form video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .video-wrapper {
    height: 100%;
  }

  .form-orientation {
    justify-content: center;
  }

  .form-orientation h2 {
    margin: 0;
  }

  .thank-you-orientation {
    flex-direction: row-reverse;
  }

  .thank-you-orientation video {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .video-wrapper img {
    display: none;
  }

  .mobile-form .video-wrapper img {
    display: block;
  }

  /* .mobile-form .top-shadow.bg-image:before {
    display: block;
    top: auto;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 32, 81, 1) 100%
    );
  } */

  .form-height,
  .form-wrapper {
    height: 100%;
  }

  .thank-you-wrapper {
    margin-left: -5.2vw;
    height: 100dvh;
    gap: 2rem;
    justify-content: center;
    background: unset;
  }

  .thank-you-wrapper a {
    transform: unset;
  }

  .thank-you-video-wrapper {
    width: 200%;
    height: 100%;
  }

  .thank-you-video-wrapper img {
    bottom: -1rem;
  }

  .thank-you-video-wrapper.bg-image:after,
  .portrait-thanks-image {
    display: none;
  }

  .landscape-thanks-image {
    display: block;
  }
}
